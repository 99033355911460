










import Vue from 'vue';

interface S {
  [index: string]: string,
}

export default Vue.extend({
  props: {
    fileName: {
      required: true,
      type: String as () => string,
    },
    fileType: {
      required: true,
      type: String as () => string,
    },
    isActive: Boolean,
  },
  computed: {
    icon(): string {
      let r = 'default';

      [
        // 'application',
        'audio',
        // 'font',
        'image',
        // 'model',
        // 'text',
        'video',
      ].forEach((e) => {
        if (this.fileType.startsWith(`${e}/`)) r = `any-${e}`;
      });

      const specific: S = {
        'image/vnd.adobe.photoshop': 'adobe-photoshop',
      };
      const s = specific[this.fileType];
      if (s) r = s;

      return r;
    },
  },
});
