






















































import Vue from 'vue';
import { ApiResource } from '@/types';
import FileIcon from '@/components/media/FileIcon.vue';
import { bytesToString } from '@/util/bytes';

export default Vue.extend({
  components: {
    FileIcon,
  },
  props: {
    file: {
      required: true,
      type: Object as () => ApiResource.CommissionFile,
      validator(obj: any): obj is ApiResource.CommissionFile {
        return obj.object === 'commission_file';
      },
    },
    isOwner: Boolean,
    isFileIconLarge: Boolean,
  },
  computed: {
    fileSize(): string {
      return bytesToString(this.file.size);
    },
  },
});
